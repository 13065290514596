















































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import { DateTime } from "luxon";
import Company from "@/app/components/tabs/view-report/Company.vue";
import Documentation from "@/app/components/tabs/view-report/Documentation.vue";
import Stakeholders from "@/app/components/tabs/view-report/Stakeholders.vue";
import Structure from "@/app/components/tabs/view-report/Structure.vue";
import CreateFlaggedComment from "@/app/components/modals/viewFlaggedComment.vue";
import CompliancePreviewModal from "@/app/components/modals/CompliancePreviewModal.vue";
import Principles from "@/app/components/tabs/view-report/Principles.vue";
import Certification from "@/app/components/tabs/view-report/Certification.vue";
import ReportItem from "@/app/components/reports/ReportItem.vue";
import ReportCommentModal from "@/app/components/modals/ReportCommentModal.vue";
import ReportService from "../../../api/report-service";
import { formatDate } from "../../../utils/helpers";
import { HOME } from "../../routes/endpoints";

enum IReportStatus {
    DRAFT = 0,
    SIGNED = 12,
    FINALISED = 6,
    PROCESSING = 7,
    AWAITING_SIGNING = 3,
    REGULATOR_APPROVAL_IN_PROGRESS = 5,
}

export default Vue.extend({
    components: {
        Company,
        Documentation,
        Stakeholders,
        Structure,
        Principles,
        Certification,
        CreateFlaggedComment,
        CompliancePreviewModal,
        ReportItem,
        ReportCommentModal,
    },
    data() {
        return {
            count: 0,
            description: "",
            comment: "",
            selectedData: {
                section: {},
                sectionType: "",
            },
            isApprovingReport: false,
            isRejectingReport: false,
            isApprovingReview: false,
            isDecliningReview: false,
            isSubmittingToNextLevelReviewer: false,
            defaultReport: {
                reportInformation: {
                    reportStatus: null,
                    reportCode: "",
                    currentDeskUser: {
                        email: "",
                        firstName: "",
                        id: "",
                        lastName: "",
                        profileImgId: "",
                        userName: "",
                    },
                    signatoryIds: [],
                    regulatorPushCount: 0,
                    regulatorApprovalStatus: 0,
                    currentLevel: 0,
                    levelStatus: 0,
                },
                companyDetails: {},
                licenses: [],
                policies: [],
                registrarCompanies: [],
                boardConsultants: [],
                governanceConsultants: [],
                auditors: [],
                charters: [],
                structure: {
                    commitees: [],
                    structurePositions: [],
                },
                principles: [],
                signatures: [],
            },
            isLoading: false,
            isDownloading: false,
            isSigning: false,
            DateTime,
            tabs: [
                {
                    text: "Company",
                    value: "company",
                    component: "company",
                },
                {
                    text: "Documentation",
                    value: "documentation",
                    component: "documentation",
                },
                {
                    text: "Governance Stakeholders",
                    value: "stakeholders",
                    component: "stakeholders",
                },
                {
                    text: "Governance Structure",
                    value: "structure",
                    component: "structure",
                },
                {
                    text: "Principles",
                    value: "principles",
                    component: "principles",
                },
                {
                    text: "Certification",
                    value: "certification",
                    component: "certification",
                },
            ],
            flaggedSections: {
                company: {},
                structure: {},
                stakeholders: {},
                documentation: {},
                certification: {},
            },
        };
    },
    computed: {
        ...mapGetters({
            profileData: "auth/getUserData",
            reportData: "reports/getReport",
        }),
        report(): any {
            return {
                ...this.defaultReport,
                ...this.reportData,
            };
        },
        isCompliant(): boolean {
            return (
                this.report?.reportInformation?.flagCount <=
                this.report?.reportInformation?.principleFlagLimit
            );
        },
        canApproveOrDenyReport(): boolean {
            return (
                this.report.reportInformation?.reportStatus ===
                    IReportStatus.PROCESSING &&
                this.report?.reportInformation?.currentDeskUser?.email ===
                    this.profileData.email
            );
        },

        canSignReport(): boolean {
            return (
                this.report.reportInformation?.reportStatus ===
                    IReportStatus.AWAITING_SIGNING &&
                (
                    this.report.signatures?.find(
                        (signature: any) =>
                            signature.userName === this.profileData.email,
                    ) as any
                )?.signatureUploadId === null
            );
        },
        canSubmitToNextLevelRegulator(): boolean {
            return (
                this.report.reportInformation?.reportStatus ===
                    IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS &&
                this.report?.reportInformation?.currentDeskUser?.email.toLowerCase() ===
                    (this.profileData?.email as string)?.toLowerCase() &&
                this.report.reportInformation.currentLevel === 1 &&
                this.report.reportInformation.levelStatus > 0
            );
        },
        canApproveOrRejectReport(): boolean {
            return (
                this.report.reportInformation?.reportStatus ===
                    IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS &&
                this.report?.reportInformation?.currentDeskUser?.email.toLowerCase() ===
                    (this.profileData?.email as string)?.toLowerCase() &&
                this.report.reportInformation.currentLevel === 2 &&
                this.report.reportInformation.levelStatus === 1
            );
        },
        itemOptions() {
            const { id } = this.$route.params;

            const val = [
                {
                    text: "Download As PDF",
                    action: () => {
                        this.isDownloading = true;

                        this.exportPdf();
                    },
                },
                {
                    text: "View History",
                    action: () => {
                        this.$router.push({
                            name: HOME.VIEW_REPORT_HISTORY.NAME,
                            params: { id },
                        });
                    },
                },
            ];

            return val;
        },
        currentTab(): { text: string; value: string; component: string } {
            const { tab }: { tab?: string } = this.$route.query;

            const res = this.tabs.find((tabItem: any) => tabItem.value === tab);

            return res ?? this.tabs[0];
        },
    },
    methods: {
        ...mapActions({
            fetchReport: "reports/fetchReportById",
        }),
        formatDate,
        commentSection(val: any) {
            this.selectedData = { ...val };
            setTimeout(() => {
                this.$bvModal.show(`modal-report-section`);
            }, 500);
        },
        hideModal() {
            this.$bvModal.hide(`modal-report-section`);
            this.fetchReportById();
        },
        isActiveTab(tab: string): boolean {
            return this.currentTab.value === tab;
        },
        async exportPdf() {
            this.isDownloading = true;

            this.$bvToast.toast(
                "The document is downloading, this might take a while. Remain on this tab while this happens and kindly try again if it exceeds 4 mins",
                {
                    title: "Document downloading",
                    variant: "success",
                    solid: true,
                },
            );

            const pages = Array.from(
                document.querySelectorAll('div[aria-label^="pdf-page-"]'),
            );

            const pdfOptions = {
                margin: 1,
                filename: `${
                    this.report.reportInformation?.reportCode
                } - NCCG Report ${new Date().toISOString().slice(0, 19)}.pdf`,
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: {
                    dpi: 192,
                    letterRendering: true,
                },
                jsPDF: {
                    unit: "in",
                    format: "letter",
                    orientation: "portrait",
                },
            };

            const downloadPDF = (elements: any, options: any) => {
                let worker = html2pdf().set(options).from(elements[0]);

                if (elements.length > 1) {
                    worker = worker.toPdf(); // worker is now a jsPDF instance

                    // add each element/page individually to the PDF render process
                    elements.slice(1).forEach((element: any, index: number) => {
                        worker = worker
                            .get("pdf")
                            .then((pdf: any) => {
                                pdf.addPage();
                            })
                            .from(element)
                            .toContainer()
                            .toCanvas()
                            .toPdf();
                    });
                }

                worker = worker.save().then(() => {
                    this.isDownloading = false;
                });
            };
            await downloadPDF(pages, pdfOptions);
        },
        setFlaggedProperties(props: any) {
            console.log(props);
            this.description = props.description;
            this.comment = props.comment;
            this.$bvModal.show("modal-flagged-comment");
        },
        async fetchReportById() {
            const { id } = this.$route.params;
            // this.isLoading = true;
            try {
                await this.fetchReport(id);
            } catch (error) {
                // this.isLoading = false;
            } finally {
                // this.isLoading = false;
            }
        },

        async approveReview() {
            const { id } = this.$route.params;
            this.isApprovingReview = true;

            const body = {
                resolution: 2,
                comment: "",
            };

            try {
                const res = await ReportService.reviewReport({
                    id: id as string,
                    body,
                });

                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.fetchReportById();
            } catch (error) {
                this.isApprovingReview = false;
            } finally {
                this.isApprovingReview = false;
            }
        },
        async declineReview(comment: string) {
            const { id } = this.$route.params;
            this.isDecliningReview = true;

            const body = {
                resolution: 5,
                comment,
            };

            try {
                const res = await ReportService.approveOrDeclineReport({
                    id: id as string,
                    body,
                });

                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.$bvModal.hide("modal-compliance-preview-decline-review");
                await this.fetchReportById();
            } catch (error) {
                this.isDecliningReview = false;
            } finally {
                this.isDecliningReview = false;
            }
        },

        async submitToNextLevelReviewer(comment: string) {
            const { id } = this.$route.params;
            this.isSubmittingToNextLevelReviewer = true;
            try {
                const res = await ReportService.approveOrDeclineReport({
                    id: id as string,
                    body: {
                        resolution: 2,
                        comment,
                    },
                });
                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.$bvModal.hide("modal-compliance-preview-submit");
                await this.fetchReportById();
            } catch (error) {
                this.isSubmittingToNextLevelReviewer = false;
            } finally {
                this.isSubmittingToNextLevelReviewer = false;
            }
        },
        async approveReport(comment: string) {
            const { id } = this.$route.params;
            this.isApprovingReport = true;

            const body = {
                resolution: 2,
                comment,
            };

            try {
                const res = await ReportService.approveOrDeclineReport({
                    id: id as string,
                    body,
                });

                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.$bvModal.hide("modal-compliance-preview-approve");
                this.fetchReportById();
            } catch (error) {
                this.isApprovingReport = false;
            } finally {
                this.isApprovingReport = false;
            }
        },
        async rejectReport(comment: string) {
            const { id } = this.$route.params;
            this.isRejectingReport = true;

            const body = {
                resolution: 3,
                comment,
            };

            try {
                const res = await ReportService.approveOrDeclineReport({
                    id: id as string,
                    body,
                });

                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.$bvModal.hide("modal-compliance-preview-query");
                this.fetchReportById();
            } catch (error) {
                this.isRejectingReport = false;
            } finally {
                this.isRejectingReport = false;
            }
        },
        fetchFromApplicableSection(toSearch: any, keyWord: string) {
            const found = toSearch.find((value: any) => {
                return (
                    value.name === keyWord ||
                    value.identity === keyWord ||
                    value.normalizedPosition === keyWord
                );
            });
            return found;
        },
        retrieveFlaggedSections(payload: any) {
            // Company Tab

            const general = this.fetchFromApplicableSection(
                payload.applicableSections,
                "General",
            );

            const company = { general };
            // End Company Tab

            // Stakeholders Tab
            const auditor = this.fetchFromApplicableSection(
                payload.applicableSections,
                "Auditor",
            );
            const registrar = this.fetchFromApplicableSection(
                payload.applicableSections,
                "Registrar",
            );
            const governanceConsultant = this.fetchFromApplicableSection(
                payload.applicableSections,
                "Governance Consultant",
            );
            const boardConsultant = this.fetchFromApplicableSection(
                payload.applicableSections,
                "Board Consultant",
            );

            const stakeholders = {
                auditor,
                registrar,
                boardConsultant,
                governanceConsultant,
            };
            // End Stakeholders Tab

            // Structures Tab
            const committees = this.fetchFromApplicableSection(
                payload.applicableSections,
                "Commitee",
            );
            const complianceOfficers = this.fetchFromApplicableSection(
                payload.structure.structurePositions,
                "S5",
            );
            const executives = this.fetchFromApplicableSection(
                payload.structure.structurePositions,
                "S1",
            );
            const secretarys = this.fetchFromApplicableSection(
                payload.structure.structurePositions,
                "S4",
            );
            const contactPersons = this.fetchFromApplicableSection(
                payload.structure.structurePositions,
                "S2",
            );
            const boardOfDirectors = this.fetchFromApplicableSection(
                payload.structure.structurePositions,
                "S3",
            );
            const investorRelations = this.fetchFromApplicableSection(
                payload.structure.structurePositions,
                "S6",
            );
            const structure = {
                committees,
                complianceOfficers,
                executives,
                secretarys,
                contactPersons,
                boardOfDirectors,
                investorRelations,
            };
            // End structures Tab

            // Signatures Tab
            const chairmanOfBoard = this.fetchFromApplicableSection(
                payload.signatures,
                "SN2",
            );
            const companySecretary = this.fetchFromApplicableSection(
                payload.signatures,
                "SN4",
            );
            const managingDirector = this.fetchFromApplicableSection(
                payload.signatures,
                "SN1",
            );
            const chairmanOfCommittee = this.fetchFromApplicableSection(
                payload.signatures,
                "SN3",
            );
            const certification = {
                chairmanOfBoard,
                managingDirector,
                companySecretary,
                chairmanOfCommittee,
            };
            // End signatures Tab

            // Documentations Tab
            // const proofOfAddress = this.fetchFromApplicableSection(
            //     payload.documents,
            //     "Proof of registered address",
            // );
            const otherDocuments = this.fetchFromApplicableSection(
                payload.documents,
                "Other documents",
            );
            // const proofOfBusinessLicense = this.fetchFromApplicableSection(
            //     payload.documents,
            //     "Proof of business License",
            // );
            // const certificationOfIncorporation =
            //     this.fetchFromApplicableSection(
            //         payload.documents,
            //         "Certificate of Incorporation",
            //     );
            const attendanceRegister = this.fetchFromApplicableSection(
                payload.documents,
                "Attendance Register",
            );

            const primaryOperatingLicense = this.fetchFromApplicableSection(
                payload.documents,
                "Primary Operating License",
            );
            // const minuteBook = this.fetchFromApplicableSection(
            //     payload.documents,
            //     "Minute Book",
            // );
            // const memorandum = this.fetchFromApplicableSection(
            //     payload.documents,
            //     "Memorandum & Articles of Association",
            // );
            const documentation = {
                attendanceRegister,
                primaryOperatingLicense,
                otherDocuments,
                // minuteBook,
                // memorandum,
                // proofOfAddress,
                // proofOfBusinessLicense,
                // certificationOfIncorporation,
            };
            // End Documentations Tab

            this.flaggedSections = {
                company,
                structure,
                stakeholders,
                certification,
                documentation,
            };
        },
        calculateFlaggedSections() {
            const count = this.report.principles.reduce(
                (prev: any, cur: any) => {
                    return cur.isFlagged ? prev + 1 : prev + 0;
                },
                0,
            );

            this.count = count;
        },
    },
    watch: {
        report(val) {
            this.retrieveFlaggedSections(val);
            this.calculateFlaggedSections();
        },
    },
    mounted() {
        const { id } = this.$route.params;
        if (id) {
            this.fetchReportById();
        }
    },
});
