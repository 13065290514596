

















































































import Vue from "vue";
import CuMenu from "../components/home/CuMenu.vue";
import CuHomeBanner from "../components/home/CuHomeBanner.vue";
import CheckList from "../components/home/CheckList.vue";
import { SETTINGS, HOME } from "../routes/endpoints";

export default Vue.extend({
    components: { CuMenu, CuHomeBanner, CheckList },
    data() {
        return {
            steps: [
                {
                    target: '[data-v-step="1"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Get Started",
                    },
                    content: `Click here to Return to the Dashboard`,
                },
                {
                    target: '[data-v-step="2"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Navigation",
                    },
                    content: `Click here to view available menus, settings and help`,
                },
                {
                    target: '[data-v-step="3"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Position",
                    },
                    content: `This is your position in this entity`,
                },
                {
                    target: '[data-v-step="4"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Menu",
                    },
                    content: `Access your profile, settings, change password and logout options`,
                },
                {
                    target: '[data-v-step="5"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Checklist",
                    },
                    content: `This is a checklist of all the steps you need to take to successfully create and submit reports`,
                },
                {
                    target: '[data-v-step="6"]', // We're using document.querySelector() under the hood
                    header: {
                        title: "Reporting",
                    },
                    content: `Click here to access reports available to your desk`,
                },
            ],
            settingsDivisions: [
                {
                    title: "Reporting Tool",
                    description: "Create, review and submit reports to the FRC",
                    logo: "ba-reporting-tool-logo",
                    button: "Start",
                    img: "ba-arrow-right-blue",
                    route: HOME.REPORTS.NAME,
                },
                {
                    title: "Governance Portal",
                    description:
                        "Setup and manage meetings, decisions and other governance activities.",
                    logo: "ba-governance-portal-logo",
                    button: "Coming Soon",
                    img: "ba-coming-soon-icon",
                    route: SETTINGS.PROFILE.NAME,
                },
                {
                    title: "Corporate Universe",
                    description:
                        "Find information regarding incorporation, people and company dealings here",
                    logo: "ba-corporate-universe-logo",
                    button: "Coming Soon",
                    img: "ba-coming-soon-icon",
                    route: SETTINGS.BUSINESS.NAME,
                },
                {
                    title: "Investor Relations",
                    description:
                        "Find information regarding incorporation, directors, employees and company dealings ",
                    logo: "ba-investor-relations-logo",
                    button: "Coming Soon",
                    img: "ba-coming-soon-icon",
                    route: SETTINGS.USER_ACCESS.NAME,
                },
                {
                    title: "Enterprise Sustainability",
                    description:
                        "Setup and manage meetings, decisions and other governance activities.",
                    logo: "ba-enterprise-sustainability-logo",
                    button: "Coming Soon",
                    img: "ba-coming-soon-icon",
                    route: SETTINGS.USER_ACCESS.NAME,
                },
                {
                    title: "Dispute Portfolio",
                    description:
                        "Manage, store and archive disputes and legal actions involving the company",
                    logo: "ba-dispute-portfolio-logo",
                    button: "Coming Soon",
                    img: "ba-coming-soon-icon",
                    route: SETTINGS.USER_ACCESS.NAME,
                },
                {
                    title: "Contract Domain",
                    description:
                        "Setup and manage meetings, decisions and other governance activities. ",
                    logo: "ba-contract-domain-logo",
                    button: "Coming Soon",
                    img: "ba-coming-soon-icon",
                    route: SETTINGS.USER_ACCESS.NAME,
                },
            ],
            recentActivities: [],
        };
    },
    mounted() {
        // this.$tours.myTour.start();
    },
});
