






























































import Vue from "vue";
import ReportItem from "@/app/components/reports/ReportItem.vue";
import ReportService from "../../../api/report-service";

export default Vue.extend({
    components: {
        ReportItem,
    },
    data() {
        return {
            isLoading: false,
            currentPage: 1,
            perPage: 10,
            rows: 100,
            search: "",
            fields: [
                {
                    key: "details",
                    label: "Details",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },

                {
                    key: "position",
                    label: "Position",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },

                {
                    key: "dateOfResolve",
                    label: "Time",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
                {
                    key: "action",
                    label: "Action",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
                {
                    key: "comment",
                    label: "Comment",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
            ],
            items: [],
            report: {
                reportInformation: {
                    reportStatus: null,
                    reportCode: "",
                    currentDeskUser: {
                        email: "",
                        firstName: "",
                        id: "",
                        lastName: "",
                        profileImgId: "",
                        userName: "",
                    },
                    signatoryIds: [],
                    regulatorPushCount: 0,
                    regulatorApprovalStatus: 0,
                    currentLevel: 0,
                    levelStatus: 0,
                },
                companyDetails: {},
                licenses: [],
                policies: [],
                registrarCompanies: [],
                boardConsultants: [],
                governanceConsultants: [],
                auditors: [],
                charters: [],
                structure: {
                    commitees: [],
                    structurePositions: [],
                },
                principles: [],
                signatures: [],
            },
        };
    },
    methods: {
        async fetchReportHistory() {
            const { id } = this.$route.params;
            this.isLoading = true;
            try {
                const res = await ReportService.fetchReportHistory(
                    id as string,
                );

                this.items = res.data;
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        async fetchReportById() {
            const { id } = this.$route.params;
            this.isLoading = true;
            try {
                const res = await ReportService.fetchReportById(id as string);

                this.report = res.data;
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted() {
        const { id } = this.$route.params;
        if (id) {
            this.fetchReportHistory();
            this.fetchReportById();
        }
    },
});
